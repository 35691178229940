<template>
  <div>
    <Header activeTit="联系我们"></Header>
    <div class="banner">
      <img src="./../assets/img/banner.png" alt="">
    </div>
    <div class="content">
      <div class="address">
        <div class="amap-wrapper">
          <el-amap class="amap-box" vid="amapDemo" :zoom="zoom" :center="center" :mapStyle="mapStyle">
            <el-amap-marker vid="component-marker" :position="[116.308836, 39.981766]" :icon="icon"></el-amap-marker>
          </el-amap>
        </div>
        <div class="text">
          <div class="line">
            <div><img src="./../assets/img/address.png" alt=""></div>
            <div>北京市海淀区彩和坊路8号天创科技大厦513室</div>
          </div>
          <div class="line">
            <div><img src="./../assets/img/mobile.png" alt=""></div>
            <div>010-53651700</div>
          </div>
          <div class="line">
            <div><img src="./../assets/img/email.png" alt=""></div>
            <div>sales@beijingpulsetech.com</div>
          </div>
        </div>
      </div>
      <div class="message">
        <div class="title">contacts</div>
        <div class="s-tit">留言反馈</div>
        <el-input v-model="msg.name" placeholder="请输入姓名" clearable class="inp"></el-input>
        <el-input v-model="msg.mobile" placeholder="请输入联系电话" clearable class="inp"></el-input>
        <el-input v-model="msg.email" placeholder="请输入联系邮箱" clearable class="inp"></el-input>
        <el-input v-model="msg.wechat" placeholder="请输入微信/QQ" clearable class="inp"></el-input>
        <el-input type="textarea" v-model="msg.content" :autosize="{ minRows: 2, maxRows: 5 }" placeholder="请输入您的留言内容" class="inp_area"></el-input>
        <el-button class="btn" @click="submitMessage">提交留言</el-button>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './layout/Header'
import Footer from './layout/Footer'
export default {
  name: 'contact',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      center: [116.308836, 39.981766],
      zoom: 16, // 缩放级别
      mapStyle: 'normal',
      icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_bs.png',
      msg: {
        name: '',
        mobile: '',
        email: '',
        wechat: '',
        content: ''
      }
    }
  },
  methods: {
    submitMessage: function () {
      console.log('提交留言', this.msg, window.fapp.services)
      window.fapp.services.messages.create({ msg: this.msg })
        .then(function (res) {
          console.log('msg插入成功', res)
        })
        .catch(function (err) {
          console.log('msg插入失败', err)
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import './../assets/css/basic.styl'
.banner
  & img
    width 100%
    height 5.65rem
.content
  padding 0.66rem 0 0.75rem 4.05rem
  .address
    display flex
    margin-bottom 0.55rem
    .amap-wrapper
      width 4.74rem
      height 3.46rem
      border 1px solid #ccc
    .text
      margin-left 0.4rem
      .line
        height 1rem
        line-height 1rem
        display flex
        font-size 0.24rem
        & img
          width 0.62rem
          vertical-align middle
          margin-right 0.4rem
  .message
    margin-left 3.57rem
    width 4.7rem
    text-align center
    .title
      color #17ba7a
      font-size 0.7rem
      margin-bottom 0.2rem
    .s-tit
      font-size $fontMid
      margin-bottom 0.39rem
    .inp
      margin-bottom 0.15rem
    .inp_area
      margin-bottom 0.5rem
    .btn
      padding 0.2rem 0.66rem
      background #17ba7a
      color #fff
      font-size $fontMid
</style>
